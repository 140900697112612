import { useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from './UserContext';

export default function useAuth() {
  const { setUser } = useContext(UserContext);
  const [error, setError] = useState(null);

  //set user in context
  const setUserContext = async (data) => {
    setUser(data);
    localStorage.setItem('userID', JSON.stringify(data.id));
    localStorage.setItem('token', data.token);
  }

  //login user
  const loginUser = async (credentials) => {
    return axios.post(`/api/tokens`, {
      headers: { 'Content-Type': 'application/json' },
      email: credentials.email,
      password: credentials.password,
    }).then((response) => {
      setUserContext(response.data);
    }).catch((err) => {
      setError(err.response.data);
    })
  }

  return {
    loginUser,
    error
  }
}