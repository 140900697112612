import React, { useState, useEffect, useContext, useRef } from "react"

import { Button } from "react-bootstrap"
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';

import CourseTree from "./components/tree/CourseTree"
import SelectCourse from "./components/course/SelectCourse"
import EditCourse from "./components/course/EditCourse"
import NewCourse from "./components/course/NewCourse"
import LinkedCourses from "./components/course/LinkedCourses"

import ImageUploader from "./ImageUploader"
import CardPreview from './CardPreview'
import axios from 'axios'
import { UserContext } from './hooks/UserContext';
import { ContentContext } from "./hooks/ContentContext";

import 'tinymce/tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/skins/ui/oxide/skin.min.css'
import 'tinymce/skins/ui/oxide/content.min.css'
import 'tinymce/skins/content/default/content.min.css'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'
import { Editor } from "@tinymce/tinymce-react"
import Header from './components/Header'

import './css/CardEditor.css';

export default function CardEditor({ location, props }) {
  const [content, setContent] = useState("");
  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedTopic, setSelectedTopic] = useState({});
  const [selectedCard, setSelectedCard] = useState();

  const { user } = useContext(UserContext);
  const toast = useRef(null);

  useEffect(() => {
    const getContent = async () => {
      // when selected node in tree is Topic
      if (!selectedCard && selectedTopic) {
        setContent("This is a Topic")
      };

      if (selectedCard) { // need to have card id before request
        const card = await getCard(selectedCard.id);
        setContent(card.body);
      }
    }
    getContent();
  }, [selectedCard]);

  useEffect(() => setContent(content), [content]);


  const handleEditorChange = (content, editor) => {
    setContent(content)
  }

  const getCard = async (cardId) => {
    let data = [];

    try {
      const res = await axios.get(`/api/cards/${cardId}`, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });
      data = res.data;
    } catch (error) {
      console.error(error);
    }
    return data

  }

  const getTopic = async (topicId) => {
    let data = [];

    try {
      const res = await axios.get(`/api/topics/${topicId}`, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });
      data = res.data;
    } catch (error) {
      console.error(error);
    }
    return data

  }

  const filterContent = (content) => {
    const parser = new DOMParser();

    // Filter out text without <p>
    // <div>Some text</div> will not be saved
    const htmlDoc = parser.parseFromString(content, 'text/html');
    const divs = htmlDoc.querySelectorAll("div");
    for (const div of divs) {
      if (!div.hasChildNodes()) {
        console.log("Removing div without child elements")
        div.remove();
      }
      for (const child of div.childNodes) {
        if (child.nodeType === 3) {// 3 is TEXT_NODE type 
          console.log("Removing text child of div element")
          div.removeChild(child);
        }
      }
    }

    return htmlDoc.body.innerHTML;
  }

  const updateContent = async (cardContent) => {
    let data = [];
    let res;

    try {
      res = await axios.put(`/api/cards/${selectedCard.id}`, {
        body: filterContent(cardContent),
        position_id: selectedCard.position_id
      }, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });
      data = res.data;
      console.log(data);
    } catch (error) {
      console.error(error);
    }
    return res;
  }

  const handlePositionDisplay = () => {
    console.log('Handling Position:')
    console.log(selectedCard['position_id'])
    return selectedCard['position_id'];
  }

  const handlePublishClick = async (event) => {
    console.log(event)
    // console.log('Content to publish:', content);
    const response = await updateContent(content);
    if (response && response.status === 200) {
      toast.current.show({ severity: 'success', summary: 'Card updated', detail: 'Content was properly saved', life: 3000 });
    } else {
      toast.current.show({ severity: 'warn', summary: 'Possible problem', detail: 'Card might not be properly saved. Please check content.', life: 5000 });
    }
  }

  const handleCourseChange = (currentCourse) => {
    setSelectedCourse(currentCourse);
    setSelectedTopic({});
  };

  const handleTopicChange = async (currentTopicId) => {
    console.log(`Our topic to update is ${currentTopicId}`)
    const currentTopic = await getTopic(currentTopicId);
    setSelectedTopic(currentTopic);
    setSelectedCard(null);
    console.log(`We changed topic and selected topic is ${currentTopic.name}`)
  };

  const handleCardChange = async (currentCardId, currentTopicId) => {
    console.log(`Our card to update is ${currentCardId}`)
    const card = await getCard(currentCardId);
    const topic = await getTopic(currentTopicId);
    setSelectedCard(card);
    setSelectedTopic(topic);
  };

  const quizDialog = (editor, target = null) => {
    return editor.windowManager.open({
      title: 'Quiz Details',
      body: {
        type: 'panel',
        items: [
          {
            type: 'input',
            name: 'title',
            label: 'Title'
          },
          {
            type: 'textarea',
            name: 'question',
            label: 'Question'
          },
          {
            type: 'input',
            name: 'answer1',
            label: 'Answer 1'
          },
          {
            type: 'input',
            name: 'answer2',
            label: 'Answer 2'
          },
          {
            type: 'input',
            name: 'answer3',
            label: 'Answer 3'
          },
          {
            type: 'selectbox', // component type
            name: 'correct_answer', // identifier
            label: 'Correct Answer',
            size: 1, // number of visible values (optional)
            items: [
              { value: '1', text: 'Answer 1' },
              { value: '2', text: 'Answer 2' },
              { value: '3', text: 'Answer 3' }
            ]
          }

        ]
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close'
        },
        {
          type: 'submit',
          text: 'Save',
          primary: true
        }
      ],
      initialData: {
        title: target ? target.getAttribute('title') : "",
        question: target ? target.getAttribute('question') : "",
        answer1: target ? target.getAttribute('answer1') : "",
        answer2: target ? target.getAttribute('answer2') : "",
        answer3: target ? target.getAttribute('answer3') : "",
        correct_answer: target ? target.getAttribute('correct_answer') : "",
      },
      onSubmit: function (api) {
        var data = api.getData();
        // Insert content when the window form is submitted
        if (target) {
          target.setAttribute('title', data.title)
          target.setAttribute('question', data.question)
          target.setAttribute('answer1', data.answer1)
          target.setAttribute('answer2', data.answer2)
          target.setAttribute('answer3', data.answer3)
          target.setAttribute('correct_answer', data.correct_answer)
        }
        else {
          editor.insertContent('<quiz title="' + data.title + '"' +
            'question="' + data.question + '"' +
            'answer1="' + data.answer1 + '"' +
            'answer2="' + data.answer2 + '"' +
            'answer3="' + data.answer3 + '"' +
            'correct_answer="' + data.correct_answer + '"' +
            '"<div> Quiz block </div> </quiz>');
        }
        api.close();
      }
    });
  };

  const noteBlockDialog = (editor, target = null) => {
    return editor.windowManager.open({
      type: 'panel',
      title: 'Note Block Details',
      size: 'large',
      body: {
        type: 'panel',
        items: [
          {
            type: 'selectbox', // component type
            name: 'template', // identifier
            label: 'Choose Template',
            size: 1, // number of visible values (optional)
            items: [
              { value: 'fact', text: 'Fun Fact' },
              { value: 'focus', text: 'Stay Focused' },
              { value: 'summary', text: 'Summary' },
              { value: 'didyouknow', text: 'Did you know?' },
              { value: 'nerdishness', text: 'A bit of Nerdish-ness' }
            ]
          },
          {
            type: 'textarea',
            name: 'text',
            label: 'Text',
            maximized: true
          },

        ]
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close'
        },
        {
          type: 'submit',
          text: 'Add',
          primary: true
        }
      ],
      initialData: {
        template: target ? target.getAttribute('template') : "",
      },
      onSubmit: function (api) {
        var data = api.getData();
        // Insert content when the window form is submitted
        if (target) {
          target.setAttribute('question', data.template)
          target.setAttribute('text', data.text)
        }
        else {
          var templates = [
            { title: 'Fun Fact', image: 'https://nerdish-images.s3.eu-central-1.amazonaws.com/button-card-images/fun_fact.png' },
            { title: 'Stay Focused', image: 'https://nerdish-images.s3.eu-central-1.amazonaws.com/button-card-images/stay_focused.png' },
            { title: 'Summary', image: 'https://nerdish-images.s3.eu-central-1.amazonaws.com/button-card-images/summary.png' },
            { title: 'Did you know?', image: 'https://nerdish-images.s3.eu-central-1.amazonaws.com/button-card-images/summary.png' },
            { title: 'A bit of Nerdish-ness', image: 'https://nerdish-images.s3.eu-central-1.amazonaws.com/button-card-images/summary.png' },
          ];

          var selected_template;
          switch (data.template) {
            case 'fact':
              selected_template = templates[0];
              break;
            case 'focus':
              selected_template = templates[1];
              break;
            case 'summary':
              selected_template = templates[2];
              break;
            case 'didyouknow':
              selected_template = templates[3];
              break;
            case 'nerdishness':
              selected_template = templates[4];
              break;
            default:
              console.log(`Sorry, there is no ${data.template} template.`);
          }

          editor.insertContent('<note img="' + selected_template.image + '" ' +
            'title="' + selected_template.title + '">' +
            data.text +
            '</note>');

        }
        api.close();
      }
    });
  };

  const getCourses = async () => {
    try {
      let courses = [];
      let currentPage = 1, totalPages;

      do {
        const { data } = await axios.get(`/api/courses?page=${currentPage}&per_page=100`, {
          headers: {
            'Authorization': `Bearer ${user.token}`,
            'X-EDITOR': 'nanoms'
          }
        });

        courses = [...courses, ...data.items.map(d => ({ value: d.id.toString(), text: d.name }))];
        totalPages = data._meta.total_pages;
        currentPage++;
      } while (currentPage <= totalPages);

      return courses;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const courseCardDialog = async (editor, target = null) => {
    const courseItems = await getCourses();

    return editor.windowManager.open({
      type: 'panel',
      title: 'Course Card Details',
      size: 'medium',
      body: {
        type: 'panel',
        items: [
          {
            type: 'selectbox', // component type
            name: 'course', // identifier
            label: 'Choose Course',
            size: 1, // number of visible values (optional)
            items: courseItems,
          },
          {
            type: 'input',
            name: 'titleText',
            label: 'Title'
          },
          {
            type: 'input',
            name: 'actionText',
            label: 'Text on button'
          },
          {
            type: 'input',
            name: 'postActionText',
            label: 'Text to show after button got clicked'
          }
        ]
      },
      buttons: [
        {
          type: 'cancel',
          text: 'Close'
        },
        {
          type: 'submit',
          text: 'Add',
          primary: true
        }
      ],
      initialData: {
        course: target ? target.getAttribute('id') : '',
        titleText: target ? target.getAttribute('title') : "You may be interested in",
        actionText: target ? target.getAttribute('actiontext') : "Add to My Topics",
        postActionText: target ? target.getAttribute('postactiontext') : "Topic added. Enjoy your reading!", // these are different quotes inside
      },
      onSubmit: function (api) {
        var data = api.getData();
        // Insert content when the window form is submitted
        if (target) {
          // this updates existing target element
          target.setAttribute('id', data.course)
          target.setAttribute('title', data.titleText)
          target.setAttribute('actiontext', data.actionText)
          target.setAttribute('postactiontext', data.postActionText)
          target.setAttribute('formaction', '#enroll/' + data.course)
        }
        else {
          // this inserts new tag
          editor.insertContent('<course id="' + data.course + '"' +
            'title="' + data.titleText + '"' +
            'actiontext="' + data.actionText + '"' +
            'formaction="#enroll/' + data.course + '"' +
            'postactiontext="' + data.postActionText + '"' +
            '>Course block</course>');

        }
        api.close();
      }
    });
  };

  const init_instance = (editor) => {
    editor.on('click', function (e) {
      if (e.target.nodeName === "QUIZ") {
        console.log('Quiz clicked:', e.target.nodeName, e.target.attributes);
        quizDialog(this, e.target);
        let quiz_element = e.target;
        console.log(quiz_element);
      }
      if (e.target.nodeName === "COURSE") {
        console.log('Course clicked:', e.target.nodeName);
        console.log('Attributes: ', e.target.attributes);
        courseCardDialog(this, e.target);
        let course_element = e.target;
        console.log(course_element);
      }

    });
  };

  return (
    <div className="App">
      <ContentContext.Provider value={{ selectedCourse, handleCourseChange }}>
        <Header />
        <Toast ref={toast} />
        <div className="container-fluid">
          <div className="row mt-4">
            <div className="col-2">
            </div>
            <div className="col-6 text-center">
              <h5>
                <div className="text-align-center">
                  Nerdish
                </div>
              </h5>
              <h3>{selectedCourse.name}</h3>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-2 course-tree">
              <div className="course-elements">
                <SelectCourse
                  searchQuery={location.search}
                />
                <EditCourse selectedCourse={selectedCourse} />
              </div>
              <CourseTree
                selectedCourse={selectedCourse}
                selectedTopic={selectedTopic}
                selectedCard={selectedCard}
                onTopicSelect={handleTopicChange}
                onCardSelect={handleCardChange}
              />
            </div>
            <div className="col-6">
              <div className="top-group">
                <div>
                  {selectedCard &&
                    <span className="p-float-label top-elements">
                      <InputNumber id="position-id" value={handlePositionDisplay()} onChange={(e) => {
                        selectedCard['position_id'] = e.value
                        setSelectedCard(selectedCard)
                      }} />
                      <label htmlFor="position-id">Position</label>
                    </span>
                  }
                </div>
                <NewCourse />
              </div>
              <div className="mark-input text-center" style={{ zIndex: -1 }}>
                <Editor
                  // apiKey="j6sj33xh2w17dxehinfd4s24wjx59z5j7eke94sibxout2nq"
                  // initialValue="<p>This is the initial content of the editor</p>"
                  value={content}
                  init={{
                    menubar: 'file edit insert view tools ',
                    menu: {
                      custom: { title: 'Media', items: 'link myCustomMenuItem' }
                    },
                    skin: false,
                    init_instance_callback: init_instance,
                    setup: function (editor) {
                      // Add a button that opens a window
                      editor.ui.registry.addButton('quiz', {
                        text: 'Quiz',
                        onAction: function () {
                          // Open window
                          quizDialog(editor);
                        }
                      });

                      editor.ui.registry.addButton('noteCard', {
                        text: 'Note',
                        onAction: function () {
                          // Open window
                          noteBlockDialog(editor);
                        }
                      });

                      editor.ui.registry.addButton('courseCard', {
                        text: 'Course Card',
                        onAction: function () {
                          // Open window
                          courseCardDialog(editor);
                        }
                      });

                      // Adds a menu item, which can then be included in any menu via the menu/menubar configuration
                      editor.ui.registry.addMenuItem('quiz', {
                        text: 'Quiz',
                        onAction: function () {
                          // Open window
                          quizDialog(editor);
                        }
                      });


                      return {
                        getMetadata: function () {
                          return {
                            name: 'Nerdish plugin',
                            url: 'http://nerdish.io'
                          };
                        }
                      };
                    },
                    height: 500,
                    plugins: [
                      'image', 'link', 'lists', 'paste',
                      'visualblocks', 'wordcount', 'code',
                      'nonbreaking', 'charmap'
                    ],
                    toolbar:
                      'undo redo | styleselect | quiz noteCard courseCard | bold italic | h1 h2 h3 | bullist numlist | superscript subscript | nonbreaking charmap | image |  code | help',
                    image_caption: true,
                    contextmenu: false,
                    font_formats:
                      "Quicksand=quicksand",
                    content_style:
                      "@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap'); body { font-family: Noto Sans; font-size: 14pt;}",
                    style_formats: [
                      {
                        title: 'Blocks', items: [
                          { title: 'text', block: 'p', wrapper: false, merge_siblings: false },
                        ]
                      }
                    ],
                    visualblocks_default_state: true,
                    end_container_on_empty_block: true,
                    forced_root_block: 'div',
                    valid_elements: 'p,h1,h2,h3,img[src|alt],figure,figcaption,div,ol,ul,li,strong,em,a[href],sup,sub',
                    valid_children: '+p[strong|em|a|#text|sup|sub],-p[img|note|course],-div[#text|ol|ul|figure|p|strong|em],+ul[li],+ol[li],-strong[img],a[#text]',
                    extended_valid_elements: "quiz[title|question|answer1|answer2|answer3|correct_answer],note[img|title],course[id|title|actiontext|formaction|postactiontext],button[type|formaction|#text]",
                    custom_elements: "~quiz,~note,~course",
                    content_css: "editor.css",
                    entity_encoding: 'raw'
                  }}
                  onEditorChange={handleEditorChange}
                />
                <ImageUploader uploadFolder={selectedCourse.id} />
                <div className="publish-button">
                  {/* Do not show button if there is no card seleted */}
                  {selectedCard &&
                    <Button size="lg" block onClick={handlePublishClick}>Save</Button>
                  }
                </div>
                <LinkedCourses courseId={selectedCourse.id} />
              </div>
            </div>
            <div className="col">
              <CardPreview content={content}
                selectedCourse={selectedCourse}
                selectedTopic={selectedTopic} />
            </div>
          </div>
        </div>
      </ContentContext.Provider>
    </div >
  )
}






