import React, { useState, useEffect, useContext } from 'react'
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { UserContext } from '../../hooks/UserContext';
import { ContentContext } from '../../hooks/ContentContext';
import qs from 'qs';


export default function SelectCourse({ searchQuery }) {
  const [selectOptions, setSelectOptions] = useState([]);
  const { user } = useContext(UserContext);
  const { selectedCourse, handleCourseChange } = useContext(ContentContext);

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    getOptions();
  }, [selectedCourse]);

  const getOptions = async () => {
    try {
      let options = [];
      let currentPage = 1, totalPages;
  
      do {
        const { data } = await axios.get(`/api/courses?page=${currentPage}&per_page=100`, {
          headers: { 
            'Authorization': `Bearer ${user.token}`, 
            'X-EDITOR': 'nanoms' 
          }
        });
  
        options = [...options, ...data.items.map(({ id, name }) => ({
          value: id,
          label: name
        }))];
  
        totalPages = data._meta.total_pages;
        currentPage++;
      } while (currentPage <= totalPages);
  
      setSelectOptions(options);
  
      if (searchQuery) {
        const { course } = qs.parse(searchQuery, { ignoreQueryPrefix: true });
        const result = options.find(({ value }) => value === parseInt(course));
        if (result) {
          handleCourseChange({ id: result.value, name: result.label });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleChange = (e) => {
    const result = selectOptions.find(({ value }) => value === e.value);
    if (result) {
      handleCourseChange({ id: result.value, name: result.label });
      console.log(`Selected course with id: ${e.value}`);
    }
  }

  return (
    <div className="selectbox">
      <Dropdown value={selectedCourse.id} options={selectOptions} onChange={handleChange} placeholder="Select a Course" optionLabel="label" filter />
    </div>
  )
}
