import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import axios from 'axios';
import { UserContext } from '../../hooks/UserContext';

export default function SelectTopicStatus({ onStatusSelect, selectedTopic }) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [value, setValue] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    const res = await axios.get('/api/topics/statuses', {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });

    const data = res.data.items

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }))

    if (selectedTopic) {
      const topicResp = await axios.get(`/api/topics/${selectedTopic.id}`, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });

      const topic = topicResp.data;

      if (topic && topic.status) {
        const selected = options.find((item) => {
          return topic.status === item.label;
        });
        setValue(selected)
        onStatusSelect(selected)
      }
    }

    setSelectOptions(options)
  }

  const handleChange = (e) => {
    onStatusSelect(e);
    setValue(e);
    console.log(`Selected topic status with id: ${e.value}`);
  }

  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 2 })
      }}
      className="selectbox"
      options={selectOptions}
      onChange={handleChange}
      value={value}
      placeholder={<div>Select topic status</div>}
    />
  )
}
