import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import useLogout from '../hooks/useLogout';


export default function LogoutButton() {
  const [loggedOut, setLoggedOut] = useState(false)
  const { logoutUser } = useLogout();

  const logout = () => {
    logoutUser();
    setLoggedOut(true)
  };

  return <Button variant="link" onClick={logout}>Log Out</Button>;
};