import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import axios from 'axios';
import { UserContext } from '../../hooks/UserContext';

export default function SelectCourseCategory({ onCategorySelect, selectedCourse }) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [value, setValue] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    async function fetchCategories() {
      await getOptions(selectedCourse);
    }

    fetchCategories();
  }, []);

  const getOptions = async (selectedCourse) => {
    const res = await axios.get('/api/courses/categories', {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });

    const data = res.data.items

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }))

    if (selectedCourse) {
      const courseResp = await axios.get(`/api/courses/${selectedCourse.id}`, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });

      const course = courseResp.data;

      if (course && course.categories) {
        const selected = options.filter((item) => {
          return course.categories.indexOf(item.value) !== -1;
        });
        setValue(selected)
        onCategorySelect(selected)
      }
    }

    setSelectOptions(options)
  }

  const handleChange = (e) => {
    if (e) {
      onCategorySelect(e);
      setValue(e);
      console.log(`Number of selected categories: ${e.length}`);
    } else {
      onCategorySelect({});
    }
  }

  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 2 })
      }}
      isMulti
      className="selectbox"
      options={selectOptions}
      value={value}
      onChange={handleChange}
      placeholder={<div>Select course category</div>}
    />
  )
}
