import React, { useState, useEffect, useRef, useContext } from 'react';
import { Tree } from 'primereact/tree';
import { ContextMenu } from 'primereact/contextmenu';
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import { Toast } from 'primereact/toast';
import NewTopic from "../topic/NewTopic"
import NewCard from "../card/NewCard"

import axios from 'axios';
import { UserContext } from '../../hooks/UserContext';
import EditTopic from '../topic/EditTopic';

const CourseTree = ({ selectedCourse, selectedTopic, selectedCard, onTopicSelect, onCardSelect, canEdit = true }) => {
  const { user } = useContext(UserContext);
  const [course, setCourse] = useState();
  const [nodes, setNodes] = useState([{}]);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const toast = useRef(null);
  const cm = useRef(null);
  const cmt = useRef(null);
  const editTopicRef = useRef();

  useEffect(() => {
    setCourse(selectedCourse);
  }, [selectedCourse]);

  useEffect(() => {
    async function fetchTreeData() {
      if (course) {
        const res = await getTopics(course.id);
        const sortedTopics = sortItemsByPosition(res.data.items);
        const topicIds = Object.values(sortedTopics).map(x => x.id)

        Promise.all(topicIds.map(id => getCards(id))).then((values) => {
          let sortedCards = [];
          for (const cards of values) {
            sortedCards.push(sortItemsByPosition(cards));
          }

          const options = sortedTopics.map((d, index) => ({
            "key": d.id,
            "label": `${d.position_id}. ${d.type === 'free' ? '(free)' : ''}  ${d.name} `,
            "data": d.name,
            "icon": "pi pi-fw pi-inbox",
            "children": sortedCards[index].map(value => ({
              "key": `${d.id}-${value.id}`,
              "label": `${value.position_id}. card${value.id}`,
              "icon": "pi pi-fw pi-file",
              "data": value.body
            }))
          }))
          setNodes(options);
        })
      }
    }

    fetchTreeData();

  }, [course]);


  const getTopics = id => axios.get(`/api/courses/${id}/topics`, {
    headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
  });

  const getCards = async (id) => {
    const res = await axios.get(`/api/topics/${id}/cards`, {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });
    return res.data.items;
  };

  const deleteCard = id => axios.delete(`/api/cards/${id}`, {
    headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
  });

  const deleteTopic = id => axios.delete(`/api/topics/${id}`, {
    headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
  });

  const compareItems = (a, b) => {
    if (a.position_id > b.position_id) {
      return 1;
    } else if (b.position_id > a.position_id) {
      return -1;
    } else if (a.id > b.id) { // id can't be equeal or absent
      return 1;
    } else {
      return -1;
    }
  }

  // sort items by position id
  const sortItemsByPosition = (itemsArray) => {
    const noPositionItems = itemsArray.filter((item) => {
      return item.position_id === null
    });
    noPositionItems.sort(compareItems);

    const itemsWithPosition = itemsArray.filter((item) => {
      return item.position_id !== null
    });
    itemsWithPosition.sort(compareItems);
    return itemsWithPosition.concat(noPositionItems);
  }

  // Recursively looking throigh the tree
  const addChildNode = (currentTree, nodeAdd) => {
    return currentTree.map((node) => {
      const [parentId] = nodeAdd.key.split('-');
      if (node.children) {
        if (node.key === parseInt(parentId)) {
          node.expanded = true;
          node.children.push(nodeAdd);
        } else {
          node.children = addChildNode(node.children, nodeAdd);
        }
        return node;
      } else {
        if (node.key === parseInt(parentId)) {
          node.expanded = true;
          node.children = [];
          node.children.push(nodeAdd);
        }
        return node;
      }
    });
  }

  const addCardNode = (newNodeData) => {
    const newTree = addChildNode(nodes, newNodeData);
    setNodes(newTree);
  }

  const addTopicNode = (newNodeData) => {
    const newTree = [...nodes, newNodeData];
    setNodes(newTree);
  }

  const updateTopicNode = (newNodeData) => {
    const destNode = nodes.find(node => node.key === newNodeData.key)
    // copy cards data for the topic
    newNodeData.children = [...destNode.children];

    const newTree = nodes.map(node =>
      node.key === newNodeData.key
        ? newNodeData
        : node
    );

    setNodes(newTree);
  }


  const contextMenuTopic = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => {
        editTopicRef.current.openModal(selectedNodeKey);
      }
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        confirmDelete();
      }
    }
  ];


  const contextMenu = [
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => {
        confirmDelete();
      }
    }
  ];

  const confirmDelete = () => {
    confirmDialog({
      message: 'Are you sure you want to delete item?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: acceptDelete,
    });
  };

  const acceptDelete = () => {
    // topic is just integer and card has key like '2-8' where 2 is topic id
    if (Number.isInteger(selectedNodeKey)) {
      const topicId = parseInt(selectedNodeKey);
      const topic = nodes.find(({ key }) => key === topicId);
      nodes.splice(nodes.indexOf(topic), 1)
      deleteTopic(topicId);
      // update state
      setSelectedNodeKey(null);
      setNodes(nodes);
      // notify user about this
      toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Topic with id ' + topicId + ' deleted', life: 3000 });
    } else {
      const [topicId, cardId] = selectedNodeKey.split('-');
      // find topic
      const topic = nodes.find(({ key }) => key === parseInt(topicId));
      const topicIndex = nodes.indexOf(topic);
      // fimd card
      const card = topic.children.find(node => node.key === selectedNodeKey);
      const cardIndex = topic.children.indexOf(card);
      // delete elenent
      nodes[topicIndex].children.splice(cardIndex, 1);
      deleteCard(cardId);
      // update state
      setSelectedNodeKey(null);
      setNodes(nodes);
      // notify user about this
      toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Card with id ' + cardId + ' deleted', life: 3000 });
    }

  }

  const handleSelection = async (event) => {
    console.log(event);

    // Can be selected node with Topic (w/o "-") or with Card (e.g. "4-20")
    if (`${event.value}`.includes("-")) {
      const [topicId, cardId] = event.value.split('-');
      onCardSelect(cardId, topicId);
    }
    else {
      const topicId = event.value.toString();
      onTopicSelect(topicId);
    }
  }

  const contextMenuHandler = (event) => {
    // do not handle context menu if edit is not allowed
    if (!canEdit) {
      return
    }
    // topics have children and  can be edited
    if (event.node.hasOwnProperty('children')) {
      cmt.current.show(event.originalEvent)
    } else { // card don't have children and can be only deleted via context menu
      cm.current.show(event.originalEvent)
    }
  }

  return (
    <div>
      <div className="card">
        {canEdit &&
          <NewTopic selectedCourse={selectedCourse} addTopicNode={addTopicNode} />
        }
        {canEdit &&
          <NewCard selectedCourse={selectedCourse} selectedTopic={selectedTopic} addCardNode={addCardNode} />
        }
        {canEdit &&
          <ContextMenu model={contextMenu} ref={cm} appendTo={document.body} />
        }
        {canEdit &&
          <ContextMenu model={contextMenuTopic} ref={cmt} appendTo={document.body} />
        }
        {canEdit &&
          <EditTopic updateTopicNode={updateTopicNode} ref={editTopicRef} />
        }

        <Toast ref={toast} />
        <Tree
          value={nodes}
          expandedKeys={expandedKeys}
          onToggle={e => setExpandedKeys(e.value)}
          selectionMode="single"
          selectionKeys={selectedNodeKey}
          onSelectionChange={handleSelection}
          onContextMenuSelectionChange={event => setSelectedNodeKey(event.value)}
          onContextMenu={event => contextMenuHandler(event)}
        />
      </div>
    </div>
  );
}

export default CourseTree;