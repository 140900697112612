import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import { UserContext } from './hooks/UserContext';
import Header from './components/Header'

export const ManageCourses = () => {
    const [courses, setCourses] = useState([])
    const [first, setFirst] = useState(0);
    const [globalFilter, setGlobalFilter] = useState(null);
    const { user } = useContext(UserContext);

    useEffect(() => {
        async function fetchData() {
            const categories_data = await getCategories();
            const course_data = await getCourses();
            const data_modified = course_data.map(course => ({
                ...course,
                last_edited_iso: course['last_edited'] ? convertDate(course['last_edited']) : '-',
                published_date_iso: course['published_date'] ? convertDate(course['published_date']) : '-',
                is_new: course['is_new'] ? 'new' : '-',
                do_not_miss: course['do_not_miss'] ? 'on' : '',
                categories: getCategoriesNames(course.categories, categories_data)
            }));
            setCourses(data_modified);
        }
        fetchData();
    }, [])

    const convertDate = (timestamp) => {
        const offset = new Date(timestamp).getTimezoneOffset() * 60 * 1000;
        const dateTime = timestamp * 1000 - offset;


        const dateFormat = new Intl.DateTimeFormat("en-GB", {
            timeStyle: "medium",
            dateStyle: "medium",
        });
        return dateFormat.format(dateTime);
    }

    const getCategoriesNames = (categoriesIDs, categories_data) => {
        return categoriesIDs.map(categoryID => {
            let category = categories_data.find(({ id }) => id === categoryID)
            if (category) {
                return category['name'];
            }
        })
    }


    const getCourses = async () => {
        const res = await axios.get(`/api/courses`, {
            headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
        });
        return res.data.items;
    }

    const getCategories = async () => {
        const res = await axios.get(`/api/courses/categories`, {
            headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
        }); return res.data.items;
    }


    const renderHeader = () => {
        return (
            <div className="table-header">
                List of Topics
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        );
    }

    const header = renderHeader();

    const nameBodyTemplate = (rowData) => {
        return <a href={`/editor?course=${rowData.id}`}>{rowData.name}</a>;
    }

    const categoriesTemplate = (rowData) => {
        const listItems = rowData.categories.map((category) =>
            <li>{category}</li>
        );
        return (
            <ul>
                {listItems}
            </ul>
        );
    }

    return (
        <div className="App">
            <Header />
            <div className="container-fluid">
                <div className="row mt-4">
                    <div className="col-2">
                    </div>
                    <div className="col-6 text-center">
                        <h2>
                            <div className="text-align-center">
                                Nerdish Topics
                            </div>
                        </h2>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-2"></div>
                    <div className="col-6">
                        <DataTable header={header} value={courses} paginator rows={20} first={first} onPage={(e) => setFirst(e.first)}
                            globalFilter={globalFilter}
                            emptyMessage="No topics found" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown">
                            <Column field="name" header="Topic" body={nameBodyTemplate} sortable style={{ width: '20%' }}></Column>
                            <Column field="id" header="ID"></Column>
                            <Column field="topics" header="Sub-Topics"></Column>
                            <Column field="categories" header="Categories" body={categoriesTemplate} style={{ width: '20%' }}></Column>
                            <Column field="do_not_miss" header="Do Not Miss"></Column>
                            <Column field="type" header="Type" sortable></Column>
                            <Column field="status" header="Status" sortable></Column>
                            <Column field="is_new" header="Is New?" sortable></Column>
                            <Column field="published_date_iso" header="Published" sortable></Column>
                            <Column field="last_edited_iso" header="Last Edited" sortable></Column>
                            {/* <Column body={actionBodyTemplate} headerStyle={{ width: '8em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} /> */}
                        </DataTable>
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </div>

    );

}