import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { UserContext } from '../../hooks/UserContext';

export default function LinkedCourses({ courseId }) {
  const [courses, setCourses] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    async function fetchCourses() {
      getCourses(courseId);
    }

    fetchCourses();
  }, [courseId]);

  const getCourses = async (courseId) => {
    const res = await axios.get(`/api/courses/${courseId}/links`, {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });
    setCourses(res.data.items);
  }

  return (
    <div className="linked-courses">
      <h5>Linked courses:</h5>
      <ul>
        {courses.map(course => {
          return <li>{course.id}. {course.name}</li>;
        })}
      </ul>
    </div>
  )
}
