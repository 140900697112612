import axios from 'axios'
import { useEffect, useState, useContext } from "react"
import { UserContext } from '../../hooks/UserContext';
import { Button, Form, Modal } from "react-bootstrap"

export default function NewCard({ selectedCourse, selectedTopic, addCardNode }) {
  const [show, setShow] = useState(false);
  const { user } = useContext(UserContext);

  // Form data
  const [position, setPosition] = useState();
  const [topic, setTopic] = useState();
  const [topicOptions, setTopicOptions] = useState([]);

  const handleClose = () => {
    setShow(false);
  }

  const handleShow = () => {
    setShow(true);
    getTopicOptions();
  }

  const createCard = async () => {
    let url = "/api/cards";
    let data;

    try {
      const response = await axios.post(url, {
        position_id: position ? parseInt(position) : null,
        body: "",
        topic_id: selectedTopic.id ? selectedTopic.id : topic
      }, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' },
      });

      data = response.data;
    } catch (error) {
      console.error(error);
    }

    setPosition()

    return data
  }

  const handleTopicChange = (event) => {
    setTopic(parseInt(event.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShow(false);
    console.log("New card is going to be created here");
    const result = await createCard();
    const cardNode = {
      "key": `${result.topic_id}-${result.id}`,
      "label": `#${result.position_id} card${result.id}`,
      "icon": "pi pi-fw pi-file",
      "data": result.body
    };
    addCardNode(cardNode);
    console.log(`Card created with result: ${result} `)
  }

  const getTopicOptions = async () => {
    let options = []
    if (selectedTopic.id) {
      options = [{ "value": selectedTopic.id, "label": selectedTopic.name }];
    } else {
      const res = await axios.get(`/api/courses/${selectedCourse.id}/topics`, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });

      const data = res.data.items

      options = data.map(d => ({
        "value": d.id,
        "label": d.name
      }))
    }

    setTopic(options[0].value);
    setTopicOptions(options)
  }


  return (
    <>
      <Button variant="light" onClick={handleShow} className='select-button'>
        Add Card
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formParentTopic">
            <Form.Label>Topic: </Form.Label>
            <Form.Control as="select" value={topic} onChange={handleTopicChange}>
              {
                topicOptions.map((option, index) => {
                  return (<option key={index} value={option.value}>{option.label}</option>)
                })
              }
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formCardPosition">
            <Form.Label>Position: </Form.Label>
            <Form.Control type="text" onChange={e => setPosition(e.target.value)} value={position} placeholder="Can be empty or number" />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}