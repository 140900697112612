import { useState, useEffect } from 'react';
import axios from 'axios';

export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userIDString = localStorage.getItem('userID');
    return (userIDString && tokenString) ? { 'id': userIDString, 'token': tokenString } : null
  };

  useEffect(() => {
    const user = getToken();

    if (user) {
      setUser(user);
    }
    setLoading(false);

  }, []);

  return {
    user,
    isLoading,
    setUser
  }
}