import { useEffect, useState, useContext } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import axios from 'axios'
import { UserContext } from '../../hooks/UserContext';
import { ContentContext } from '../../hooks/ContentContext';
import SelectCourseCategory from './SelectCourseCategory'
import SelectCourseType from './SelectCourseType'
import SelectCourseStatus from './SelectCourseStatus'
import { FileUpload } from 'primereact/fileupload';

export default function EditCourse() {
  const [show, setShow] = useState(false);
  const { user } = useContext(UserContext);
  const { selectedCourse, handleCourseChange } = useContext(ContentContext);

  // Form data
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [categories, setCategories] = useState([]);
  const [doNotMiss, setDoNotMiss] = useState(false);
  const [type, setType] = useState();
  const [status, setStatus] = useState();
  const [uploadUrl, setUploadUrl] = useState();

  const handleClose = () => setShow(false);
  const handleShow = async () => {
    if (selectedCourse) {
      await getCourse();
    }
    setShow(true);
  }

  const updateCourse = async () => {
    let url = `/api/courses/${selectedCourse.id}`;
    let data;

    try {
      const response = await axios.put(url, {
        name: name,
        description: description,
        cover_image: uploadUrl,
        categories: categories.map(cat => cat.value),
        do_not_miss: doNotMiss,
        type_id: parseInt(type.value),
        status_id: parseInt(status.value)
      }, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' },
      });
      data = response.data;
    } catch (error) {
      console.error(error);
    }

    setName()
    setDescription()
    setCategories()
    setDoNotMiss(false)
    setType()
    setStatus()
    setUploadUrl()
    return data
  }

  useEffect(() => {
  }, []);

  const getCourse = async () => {
    const res = await axios.get(`/api/courses/${selectedCourse.id}`, {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });

    const course = res.data;

    setName(course.name)
    setDescription(course.description)
    setDoNotMiss(course.do_not_miss)
    setUploadUrl(course.cover_image)

  };

  const handleSubmit = async e => {
    e.preventDefault();
    setShow(false);
    console.log("New course is going to be created here")
    const result = await updateCourse();
    handleCourseChange({ id: result.id, name: result.name })
    console.log(`Course created with result: ${result} `)
  }

  const handleCategoryChange = (currentCategory) => {
    setCategories(currentCategory);
    console.log(`We changed category and selected category is ${currentCategory}`)
    console.log(currentCategory)
  };

  const handleTypeChange = (currentType) => {
    setType(currentType);
    console.log(`We changed type and selected category is ${currentType.name}`)
    console.log(currentType)
  };

  const handleStatusChange = (currentStatus) => {
    setStatus(currentStatus);
    console.log(`We changed type and selected status is ${currentStatus.name}`)
    console.log(currentStatus)
  };

  const onBeforeSend = (e) => {
    e.formData.append('folder', 'course_cover_images');
  }

  const onUpload = (event) => {
    const url = JSON.parse(event.xhr.response).location
    setUploadUrl(url);
  }

  return (
    <>
      <Button variant="light" onClick={handleShow}>
        Edit
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formCourseName">
            <Form.Label>Name: </Form.Label>
            <Form.Control type="text" onChange={e => setName(e.target.value)} value={name} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formCourseDescription">
            <Form.Label>Description: </Form.Label>
            <Form.Control type="text" onChange={e => setDescription(e.target.value)} value={description} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formCourseCategory">
            <Form.Label>Category: </Form.Label>
            {/* <Form.Control type="text" onChange={e => handleCategoryChange(e.target.value)} value={categories} placeholder="" /> */}
            <SelectCourseCategory
              onCategorySelect={handleCategoryChange}
              selectedCourse={selectedCourse}
            />
          </Form.Group>
          <Form.Group controlId="formCourseDoNotMiss">
            <Form.Label>Do Not Miss: </Form.Label>
            <Form.Check type="checkbox" onChange={e => setDoNotMiss(e.target.checked)} checked={doNotMiss} />
          </Form.Group>
          <Form.Group controlId="formCourseType">
            <Form.Label>Type: </Form.Label>
            <SelectCourseType
              onTypeSelect={handleTypeChange}
              selectedCourse={selectedCourse}
            />
          </Form.Group>
          <Form.Group controlId="formCourseStatus">
            <Form.Label>Status: </Form.Label>
            <SelectCourseStatus
              onStatusSelect={handleStatusChange}
              selectedCourse={selectedCourse}
            />
          </Form.Group>
          <Form.Group controlId="formUploadCoverImage">
            <Form.Label>Cover Image: </Form.Label>
            <Form.Control type="text" value={uploadUrl} placeholder="" onChange={e => setUploadUrl(e.target.value)} />
            <FileUpload
              mode="basic"
              name="file"
              url="/api/upload"
              accept="image/*"
              maxFileSize={5000000}
              onBeforeSend={onBeforeSend}
              onUpload={onUpload}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}