import React from 'react';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import Logout from "../pages/Logout"

const Header = () => {
  const items = [
    {
      label: 'Editor',
      icon: 'pi pi-fw pi-pencil',
      url: '/editor'
    },
    {
      label: 'Topics',
      icon: 'pi pi-fw pi-file',
      url: '/manage-topics'
    },
    {
      label: 'Questions',
      icon: 'pi pi-fw pi-question',
      url: '/questions',
    },
    {
      label: 'Viewer',
      icon: 'pi pi-fw pi-eye',
      url: '/view'
    },
  ];

  return (
    <div>
      <div className="card">
        <Menubar model={items} end={<Logout />} />
      </div>
    </div>
  );
}


export default Header;