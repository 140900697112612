import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { UserContext } from '../../hooks/UserContext';

import './css/CourseCardPreview.css';

export default function CourseCardPreview({ courseId }) {
  const [course, setCourse] = useState({});
  const { user } = useContext(UserContext);

  useEffect(() => {
    async function fetchCourse() {
      getCourse(courseId);
    }

    fetchCourse();
  }, [courseId]);

  const getCourse = async (courseId) => {
    const res = await axios.get(`/api/courses/${courseId}`, {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });
    setCourse(res.data);
  }

  return (
    <div className="course-card">
      <div className="cover-image-container">
        <img src={course.cover_image} alt={`Course ${course.name} cover image`} />
      </div>
      <div class="description">
        <div className="course-name">{course.name}</div>
        <div className="course-data">{course.topics} topics, {course.cards} cards, {course.duration} mins to read</div>
      </div>
    </div>
  )
}
