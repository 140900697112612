import axios from 'axios'
import { useState, useContext } from "react"
import { UserContext } from '../../hooks/UserContext';

import { Button, Form, Modal } from "react-bootstrap"
import SelectTopicStatus from "./SelectTopicStatus"
import SelectTopicType from "./SelectTopicType"

export default function NewTopic({ selectedCourse, addTopicNode }) {
  const [show, setShow] = useState(false);
  const { user } = useContext(UserContext);

  // Form data
  const [name, setName] = useState();
  const [position, setPosition] = useState();
  const [duration, setDuration] = useState();
  const [description, setDescription] = useState();
  const [status, setStatus] = useState();
  const [type, setType] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createTopic = async () => {
    let url = "/api/topics";
    let data;

    console.log(duration)

    try {
      const response = await axios.post(url, {
        name: name,
        position_id: position ? parseInt(position) : null,
        duration: duration ? parseInt(duration) : 1,
        description: description,
        course_id: parseInt(selectedCourse.id),
        status_id: parseInt(status.value),
        type_id: parseInt(type.value),
      }, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' },
      });
      data = response.data;
    } catch (error) {
      console.error(error);
    }

    setName()
    setDescription()
    setPosition()
    setDuration()
    setStatus()
    setType()
    return data
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setShow(false);
    console.log("New topic is going to be created here")
    const result = await createTopic();
    const topicNode = {
      "key": result.id,
      "label": `${result.position_id}. ${result.type === 'free' ? '(free)' : ''}  ${result.name} `,
      "icon": "pi pi-fw pi-inbox",
      "data": result.name,
      "children": []
    };
    console.log(`Topic request got following response: `)
    console.log(result)
    addTopicNode(topicNode);
  }

  const handleStatusChange = (currentStatus) => {
    setStatus(currentStatus);
    console.log(`We changed type and selected status is ${currentStatus.name}`)
    console.log(currentStatus)
  };

  const handleTypeChange = (currentType) => {
    setType(currentType);
    console.log(`We changed type and selected type is ${currentType.name}`)
    console.log(currentType)
  };

  return (
    <>
      <Button variant="light" onClick={handleShow} className='select-button'>
        Add Topic
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formTopicName">
            <Form.Label>Name: </Form.Label>
            <Form.Control type="text" onChange={e => setName(e.target.value)} value={name} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formTopicDescription">
            <Form.Label>Description: </Form.Label>
            <Form.Control type="text" as='textarea' onChange={e => setDescription(e.target.value)} value={description} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formTopicPosition">
            <Form.Label>Position: </Form.Label>
            <Form.Control type="text" onChange={e => setPosition(e.target.value)} value={position} placeholder="Can be empty or number" />
          </Form.Group>
          <Form.Group controlId="formTopicDuration">
            <Form.Label>Duration: </Form.Label>
            <Form.Control type="text" onChange={e => setDuration(e.target.value)} value={duration} placeholder="Can be empty or number" />
          </Form.Group>
          <Form.Group controlId="formTopicStatus">
            <Form.Label>Status: </Form.Label>
            <SelectTopicStatus
              onStatusSelect={handleStatusChange}
            />
          </Form.Group>
          <Form.Group controlId="formTopicType">
            <Form.Label>Type: </Form.Label>
            <SelectTopicType
              onTypeSelect={handleTypeChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}