import axios from 'axios'
import { useState, useRef, forwardRef, useImperativeHandle, useEffect, useContext } from "react"
import { UserContext } from '../../hooks/UserContext';
import { Button, Form, Modal } from "react-bootstrap"
import { Toast } from 'primereact/toast';
import SelectTopicStatus from "./SelectTopicStatus"
import SelectTopicType from "./SelectTopicType"

const EditTopic = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [topic, setTopic] = useState({});
  const { user } = useContext(UserContext);
  const toast = useRef(null);

  // Form data
  const [status, setStatus] = useState();
  const [type, setType] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }

  useEffect(() => {
    async function fetchTopic() {
      if (topic.id) {
        await getTopic();
      }
    }

    fetchTopic();

  }, [show]);

  useImperativeHandle(ref, () => ({
    openModal: (selectedKey) => {
      handleShow();
      setTopic({ id: selectedKey });
    }
  }));

  const updateTopic = async () => {
    let url = `/api/topics/${topic.id}`;
    let data;

    try {
      const response = await axios.put(url, {
        name: topic.name,
        position_id: topic.position ? parseInt(topic.position) : null,
        duration: topic.duration ? parseInt(topic.duration) : 1,
        description: topic.description,
        status_id: parseInt(status.value),
        type_id: parseInt(type.value)
      }, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' },
      });
      data = response.data;
    } catch (error) {
      console.error(error);
    }

    setTopic({});
    return data
  }

  const getTopic = async () => {
    const res = await axios.get(`/api/topics/${topic.id}`, {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });
    const data = res.data;

    const topicObj = {
      id: topic.id,
      name: data.name,
      description: data.description,
      position: data.position_id,
      duration: data.duration
    };

    setTopic(topicObj);
  };


  const handleSubmit = async e => {
    e.preventDefault();
    setShow(false);
    console.log("New topic is going to be created here")
    const result = await updateTopic();
    const topicNode = {
      "key": result.id,
      "label": `${result.position_id}. ${result.type === 'free' ? '(free)' : ''}  ${result.name} `,
      "icon": "pi pi-fw pi-inbox",
      "data": result.name,
      "children": []
    };
    console.log(`Topic request got following response: `)
    console.log(result)
    props.updateTopicNode(topicNode);
    toast.current.show({ severity: 'info', summary: 'Updated', detail: 'Topic "' + topic.name + '" updated', life: 3000 });
  }

  const handleStatusChange = (currentStatus) => {
    setStatus(currentStatus);
    console.log(`We changed status and selected status is ${currentStatus}`)
    console.log(currentStatus)
  };

  const handleTypeChange = (currentType) => {
    setType(currentType);
    console.log(`We changed type and selected type is ${currentType}`)
    console.log(currentType)
  };

  return (
    <>
      <Toast ref={toast} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formTopicName">
            <Form.Label>Name: </Form.Label>


            <Form.Control type="text" onChange={e => setTopic({ ...topic, name: e.target.value })} value={topic.name} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formTopicDescription">
            <Form.Label>Description: </Form.Label>
            <Form.Control type="text" as='textarea' onChange={e => setTopic({ ...topic, description: e.target.value })} value={topic.description} placeholder="" />
          </Form.Group>
          <Form.Group controlId="formTopicPosition">
            <Form.Label>Position: </Form.Label>
            <Form.Control type="text" onChange={e => setTopic({ ...topic, position: e.target.value })} value={topic.position} placeholder="Can be empty or number" />
          </Form.Group>
          <Form.Group controlId="formTopicDuration">
            <Form.Label>Duration: </Form.Label>
            <Form.Control type="text" onChange={e => setTopic({ ...topic, duration: e.target.value })} value={topic.duration} placeholder="Can be empty or number" />
          </Form.Group>
          <Form.Group controlId="formTopicStatus">
            <Form.Label>Status: </Form.Label>
            <SelectTopicStatus
              onStatusSelect={handleStatusChange}
              selectedTopic={topic}
            />
          </Form.Group>
          <Form.Group controlId="formTopicType">
            <Form.Label>Type: </Form.Label>
            <SelectTopicType
              onTypeSelect={handleTypeChange}
              selectedTopic={topic}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default EditTopic