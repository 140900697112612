import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select'
import axios from 'axios';
import { UserContext } from '../../hooks/UserContext';

export default function SelectCourseStatus({ onStatusSelect, selectedCourse }) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [value, setValue] = useState([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = async () => {
    const res = await axios.get('/api/courses/statuses', {
      headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
    });

    const data = res.data.items

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }))

    if (selectedCourse) {
      const courseResp = await axios.get(`/api/courses/${selectedCourse.id}`, {
        headers: { 'Authorization': `Bearer ${user.token}`, 'X-EDITOR': 'nanoms' }
      });

      const course = courseResp.data;

      if (course && course.status) {
        const selected = options.find((item) => {
          return course.status === item.label;
        });
        setValue(selected)
        onStatusSelect(selected)
      }
    }

    setSelectOptions(options)
  }

  const handleChange = (e) => {
    onStatusSelect(e);
    setValue(e);
    console.log(`Selected course status with id: ${e.value}`);
  }

  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 2 })
      }}
      className="selectbox"
      options={selectOptions}
      onChange={handleChange}
      value={value}
      placeholder={<div>Select course status</div>}
    />
  )
}
