
export default function ResetPasswordConfirmation() {

  return (
    <div className="p-d-flex p-jc-center">
      <div className="prime-card">
        <div className="p-d-flex p-ai-center p-dir-col p-pt-8 p-px-3">
          <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'green' }}></i>
          <h5 className="p-text-center" style={{ marginTop: '2em' }} >​​Your password has been successfully updated.</h5>
          <p>You can access your Nerdish account using your new password now.</p>
          <div><strong>Nerdish Team</strong></div>
        </div>
      </div >
    </div >
  )
}