import { useHistory } from 'react-router-dom';

export default function useLogout() {
  let history = useHistory();

  const logoutUser = async () => {
    localStorage.removeItem('userID');
    localStorage.removeItem('token');
    history.push('/login');
  }

  return {
    logoutUser
  }

}